import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import Stars from "./Stars";
import StarRanking from "./StarRanking";
import StarRankingContent from "./StarRankingContent/StarRankingContent";
import StarRankingItem from "./StarRankingContent/StarRankingItem";
import { satisfaction } from "./MockData";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "star-ranking"
    }}>{`Star ranking`}</h1>
    <h2 {...{
      "id": "stars-component"
    }}>{`Stars Component`}</h2>
    <Props of={Stars} mdxType="Props" />
    <h3 {...{
      "id": "default-stars"
    }}>{`Default stars`}</h3>
    <Playground __position={1} __code={'<Stars ranking=\"3.4\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Stars,
      StarRanking,
      StarRankingContent,
      StarRankingItem,
      satisfaction,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Stars ranking='3.4' mdxType="Stars" />
    </Playground>
    <h3 {...{
      "id": "custom-stars"
    }}>{`Custom stars`}</h3>
    <Playground __position={2} __code={'<Stars\n  ranking=\"3.4\"\n  starsSize=\"32px\"\n  emptyColor=\"OldLace\"\n  ratedColor=\"Gold\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Stars,
      StarRanking,
      StarRankingContent,
      StarRankingItem,
      satisfaction,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Stars ranking='3.4' starsSize="32px" emptyColor="OldLace" ratedColor="Gold" mdxType="Stars" />
    </Playground>
    <h2 {...{
      "id": "star-ranking-component"
    }}>{`Star Ranking Component`}</h2>
    <Props of={StarRanking} mdxType="Props" />
    <Playground __position={4} __code={'<StarRanking\n  source=\"data\"\n  data={satisfaction}\n  template=\"customer-satisfaction\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Stars,
      StarRanking,
      StarRankingContent,
      StarRankingItem,
      satisfaction,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <StarRanking source="data" data={satisfaction} template="customer-satisfaction" mdxType="StarRanking" />
    </Playground>
    <h3 {...{
      "id": "star-ranking-content--starrankingitem"
    }}>{`Star Ranking Content & StarRankingItem`}</h3>
    <p>{`Props of StarRankingItem`}</p>
    <Props of={StarRankingItem} mdxType="Props" />
    <Playground __position={6} __code={'<StarRanking>\n  <StarRankingContent>\n    <StarRankingItem\n      title=\"First Notice of Loss:\"\n      ranking=\"5\"\n      starsSize=\"14px\"\n    />\n    <StarRankingItem\n      title=\"Estimation of Process:\"\n      ranking=\"2.5\"\n      starsSize=\"14px\"\n    />\n    <StarRankingItem title=\"Repair Process:\" ranking=\"1\" starsSize=\"14px\" />\n    <StarRankingItem title=\"Claims Servicing:\" ranking=\"0\" starsSize=\"14px\" />\n    <StarRankingItem\n      title=\"Rental Experience:\"\n      ranking=\"0\"\n      starsSize=\"14px\"\n    />\n    <StarRankingItem title=\"Settlement:\" ranking=\"0\" starsSize=\"14px\" />\n  </StarRankingContent>\n</StarRanking>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Stars,
      StarRanking,
      StarRankingContent,
      StarRankingItem,
      satisfaction,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StarRanking mdxType="StarRanking">
    <StarRankingContent mdxType="StarRankingContent">
        <StarRankingItem title='First Notice of Loss:' ranking="5" starsSize="14px" mdxType="StarRankingItem" />
		<StarRankingItem title='Estimation of Process:' ranking="2.5" starsSize="14px" mdxType="StarRankingItem" />
		<StarRankingItem title='Repair Process:' ranking="1" starsSize="14px" mdxType="StarRankingItem" />
		<StarRankingItem title='Claims Servicing:' ranking="0" starsSize="14px" mdxType="StarRankingItem" />
        <StarRankingItem title='Rental Experience:' ranking="0" starsSize="14px" mdxType="StarRankingItem" />
        <StarRankingItem title='Settlement:' ranking="0" starsSize="14px" mdxType="StarRankingItem" />
    </StarRankingContent>
  </StarRanking>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      